import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NavMenu from "../nav/navMenu";
import TitleBanner from "../components/titleBanner";
import SideBar from "../components/sideBar";
import axios, { Axios } from "axios";
import TopBar from "../components/topBar";
import { List, ListItem } from "@mui/material";
import BottomBanner from "../components/bottomBanner";
import Box from "@mui/material/Box";

const useStyles = makeStyles({
  container: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    margin: "0 0 20px 0",
    width: "100%",
  },
  box: {
    backgroundColor: "#ffffff",
    padding: "10px",
    margin: "10px",
    border: 1,
    borderColor: "primary.main",
  },
  paperContainer: {
    height: "100vh",
    zIndex: -1,
  },
  dropDownContainer: {
    margin: "0 0 20px 0",
  },
  sfilogo: {
    position: "inherited",
    top: "0",
    left: "0",
    height: "10%",
  },
  title: {
    position: "absolute",
    top: "20%",
    left: "10%",
    zIndex: 0,
  },
  navmenu: {},
  text: {
    padding: 20,
    alignSelf: "center",
    justifySelf: "center",
  },
  subtitle: {
    textAlign: "center",
  },
  button: {
    left: "35%",
  },
  topBar: {
    width: "100%",
    left: 0,
    top: 0,
    position: "absolute",
  },
});

const AboutPage = () => {
  const classes = useStyles();
  const colour = "secondary";
  const title = "About Us";
  const [dbnewsletters, setDbnewsletters] = useState([]);

  useEffect(() => {
    const getNewsletters = () => {
      axios
        .get("https://advanceserver-45066d4d7734.herokuapp.com/getnewsletters")
        .then((response) => {
          const newsletterData = response.data;
          newsletterData.sort(function (a, b) {
            return new Date(b.publicationDate) - new Date(a.publicationDate);
          });

          setDbnewsletters(newsletterData);
        });
    };
    getNewsletters();
  }, []);

  return (
    <>
      <TopBar className={classes.topBar} />
      <NavMenu props={colour} className={classes.navmenu}></NavMenu>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <TitleBanner props={title} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={0} sm={0} md={1} lg={1} />
        <Grid item xs={12} sm={12} md={6} lg={6} order={{ xs: 1, sm: 1 }}>
          <Box
            className={classes.box}
            border={0.5}
            sx={{ borderColor: "#e1e1e1" }}
          >
            <Typography variant="h2" color="#112B19" tabIndex="0">
              The ADVANCE CRT – What is it?
            </Typography>
            <Typography variant="body1" tabIndex="0">
              The ADVANCE CRT is a Science Foundation Ireland Centre for
              Research Training focused on Future Networks and the Internet of
              Things with applications in sustainable and independent living.
              ADVANCE will train 120 PhD students, recruited in four annual
              cohorts of 30 students across the five partner universities,
              beginning with the academic year 2019/20
            </Typography>
            <br aria-hidden="true" />

            <Typography variant="h3" tabIndex="0">
              ADVANCE's Vision
            </Typography>
            <Typography variant="body1" tabIndex="0">
              We live in a society where ubiquitous, high-speed communication
              networks enable, and increasingly dominate, our family, social and
              working lives. These networks enable an ever-increasing range of
              human-to-machine and machine-to-machine (M2M) communication
              applications in the Internet of Things. This expanding
              hyper-connectivity will create new communication, sensing and
              control services, and forms of interconnectivity and services
              between people and Things in ways we cannot yet even fully
              envisage. Two societal pressures drive the need for
              hyper-connectivity – Urbanisation and Rural Depopulation.
              <br aria-hidden="true" />
              <br aria-hidden="true" />
              Meeting both the technical and societal challenges of global
              hyper-connectivity requires multi- and inter-disciplinary
              approaches. Our vision is to train the next generation of
              researchers who will seek solutions to the technical and societal
              challenges of global hyper-connectivity between large numbers of
              People and Things. We will explore opportunities for a range of
              novel applications in sustainable and independent living enabled
              by such networks that will become critically important to sustain
              large dense cities and alleviate problems of rural depopulation.
              Given the far-reaching technological, economic, social and
              personal impacts of hyper-connectivity, our researchers will, in
              addition to deep competence in their individual disciplines, need
              understanding of these wider ethical, social and economic aspects.
              They will need the ability to collaborate with, learn from, and
              communicate their discipline-specific knowledge with other
              disciplines and the public who will be the beneficiaries of their
              collective expertise.
            </Typography>
            <br aria-hidden="true" />

            <Typography variant="h3" tabIndex="0">
              ADVANCE PhD Programme
            </Typography>
            <Typography variant="body1" tabIndex="0">
              The 4-year PhD Programme features:
            </Typography>
            <Typography tabIndex="0">
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>
                  Intensive Summer, Autumn and Winter School events throughout
                  the Programme
                </ListItem>
                <ListItem>
                  Opportunities for multidisciplinary research
                </ListItem>
                <ListItem>
                  Supervision by internationally renowned Principal
                  Investigators
                </ListItem>
              </List>
            </Typography>
            <Typography tabIndex="0">
              In addition, scholars benefit from:
            </Typography>
            <Typography tabIndex="0">
              {" "}
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>
                  Access to a network of research groups from Universities and
                  Research Institutes from around the world that will offer our
                  students placement opportunities
                </ListItem>
                <ListItem>Professional skills training allowance </ListItem>
                <ListItem>
                  Travel funds to national training days and international
                  conferences
                </ListItem>
                <ListItem>Equipment (including a laptop or PC)</ListItem>
                <ListItem>Generous tax-free annual stipend of €22,000</ListItem>
                <ListItem>
                  Fee contribution of €5500 towards University fees (fee rate
                  for EU nationals)
                </ListItem>
              </List>
            </Typography>
            <Typography variant="h3">
              The Centre for Research Training Cohort Model
            </Typography>
            <iframe
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/oSuDRbeH68w?si=DryQF5uIZeNfj3ke"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Box>
        </Grid>
        <Grid item xs={0} sm={0} md={1} lg={1} />
        <Grid item xs={12} sm={12} md={4} lg={4} order={{ xs: 2, sm: 2 }}>
          {dbnewsletters[0] && <SideBar props={dbnewsletters[0]} />}
        </Grid>
      </Grid>

      <BottomBanner />
    </>
  );
};

export default AboutPage;
