import React, { useState, useEffect } from "react";
import { Box, Grid, Stack, Divider } from "@mui/material";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { Navigate, useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  paper: {
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "750px",
    minHeight: "270px",
    width: "100%",
    margin: "10px",
    position: "relative",
    backgroundColor: "#ffffff",
  },

  box: {
    width: "270px",
    height: "270px",
    borderRadius: 700,
    margin: "5px 5px 5px 15px",
  },
  image: {
    width: "80%",
    height: "80%",
    borderRadius: 700,
  },
  paragraph: {
    paddingLeft: 10,
    margin: 2,
  },
  button: {
    margin: "5px 0px 5px 5px",
    padding: "5px",
    alignSelf: "flex-end",
    justifySelf: "end",
  },
  bio: {
    display: "block",

    maxWidth: "450px",
    height: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

export default function PersonPaper(props) {
  const classes = useStyles();
  const person = props.props;
  const universities = [
    "Other",
    "University College Cork",
    "Munster Technological University",
    "Trinity College Dublin",
    "Technological University Dublin",
    "Maynooth University",
  ];
  const navigate = useNavigate();
  const [image, setImage] = useState("profile2.png");

  async function handleDetailsClick(e) {
    navigate(`/people/${e.target.value}`);
  }

  async function handleImage() {
    if (person.profileImage) {
      setImage(person.profileImage);
    } else {
      setImage("profile2.png");
    }
  }

  useEffect(() => {
    handleImage();
  }, [person.profileImage, image]);
  return (
    <Box className={classes.paper}>
      <Grid container>
        <Grid item xs={12} sm={6} md={4} lg={4} order={{ xs: 1, sm: 1 }}>
          <Box className={classes.box}>
            <img
              src={require(`../profileImages/${image}`)}
              className={classes.image}
              alt={`Image of ${person.firstName + " " + person.surname}`}
              tabIndex="1"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={8} order={{ xs: 2, sm: 2 }}>
          <Stack>
            <Typography
              className={classes.paragraph}
              variant="subtitle1"
              tabIndex="0"
            >
              {person.surname + ", " + person.firstName}
            </Typography>
            {!!person.isStudent && (
              <Typography
                className={classes.paragraph}
                variant="subtitle2"
                tabIndex="0"
              >
                Student, {universities[person.universityID]}
              </Typography>
            )}
            {!!(person.isSupervisor && !person.isExec) && (
              <Typography
                className={classes.paragraph}
                variant="subtitle2"
                tabIndex="0"
              >
                Supervisor, {universities[person.universityID]}
              </Typography>
            )}
            {!!(person.isExec && !person.isDirector) && (
              <Typography
                className={classes.paragraph}
                variant="subtitle2"
                tabIndex="0"
              >
                Co-Lead, {universities[person.universityID]}
              </Typography>
            )}
            {!!(person.isExec && person.isDirector) && (
              <Typography
                className={classes.paragraph}
                variant="subtitle2"
                tabIndex="0"
              >
                Director, {universities[person.universityID]}
              </Typography>
            )}
            <Divider aria-hidden="true" />
            <Box noWrap className={classes.bio} component="div">
              <Typography
                noWrap
                className={classes.paragraph}
                style={{ whiteSpace: "pre-line" }}
                aria-hidden="true"
              >
                {person.bio.substring(1, person.bio.length - 1)}
              </Typography>
            </Box>
            <Box className={classes.button}>
              <Button
                variant="contained"
                onClick={(e) => handleDetailsClick(e)}
                value={Object.values(person)[0]}
              >
                View Profile
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
