import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NavMenu from "../nav/navMenu";
import TitleBanner from "../components/titleBanner";
import TopBar from "../components/topBar";
import { List, ListItem } from "@mui/material";
import BottomBanner from "../components/bottomBanner";
import Box from "@mui/material/Box";
import SideBar from "../components/sideBar";
import axios, { Axios } from "axios";

const useStyles = makeStyles({
  container: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    margin: "0 0 20px 0",
    width: "100%",
  },
  box: {
    backgroundColor: "#ffffff",
    padding: "10px",
    margin: "10px",
    border: 1,
    borderColor: "primary.main",
  },
  paperContainer: {
    height: "100vh",
    zIndex: -1,
  },
  dropDownContainer: {
    margin: "0 0 20px 0",
  },
  sfilogo: {
    position: "inherited",
    top: "0",
    left: "0",
    height: "10%",
  },
  title: {
    position: "absolute",
    top: "20%",
    left: "10%",
    zIndex: 0,
  },
  navmenu: {
    zIndex: 1,
  },
  text: {
    padding: 20,
    alignSelf: "center",
    justifySelf: "center",
  },
  subtitle: {
    textAlign: "center",
  },
  button: {
    left: "35%",
  },
  topBar: {
    width: "100%",
    left: 0,
    top: 0,
    position: "absolute",
  },
});

const PlacementProgrammePage = () => {
  const classes = useStyles();
  const colour = "secondary";
  const title = "ADVANCE Placement Programme";
  const [dbnewsletters, setDbnewsletters] = useState([]);

  useEffect(() => {
    const getNewsletters = () => {
      axios
        .get("https://advanceserver-45066d4d7734.herokuapp.com/getnewsletters")
        .then((response) => {
          const newsletterData = response.data;
          newsletterData.sort(function (a, b) {
            return new Date(b.publicationDate) - new Date(a.publicationDate);
          });

          setDbnewsletters(newsletterData);
        });
    };
    getNewsletters();
  }, []);

  return (
    <>
      <TopBar className={classes.topBar} />
      <NavMenu props={colour} className={classes.navmenu}></NavMenu>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <TitleBanner props={title} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={0} sm={0} md={1} lg={1} />
        <Grid item xs={12} sm={12} md={6} lg={6} order={{ xs: 1, sm: 1 }}>
          <Box
            className={classes.box}
            border={0.5}
            sx={{ borderColor: "#e1e1e1" }}
          >
            <Typography variant="h2" color="#112B19">
              Placment with ADVANCE CRT
            </Typography>
            <Typography variant="body1" tabIndex="0">
              A core feature of the SFI CRT Programme is that each ADVANCE
              student will have the opportunity to go on an extended work
              placement with an external organisation – industry, NGO, or
              academic research, nationally or internationally. This allows the
              student to gain relevant experience, apply their training in a
              work context, enhance their teamworking skills, and build their
              network of contacts. Work placement also allows the external
              organisation to assess a student as a potential future employee
              and provide feedback on the appropriateness of their skillset. The
              key differentiator of the CRT postgraduate training programme is
              that training is not just student-focused, but also
              student-guided. This is evident in the Training Placement
              component of the programme where students have the opportunity to
              get experiential learning in work environments which will help
              them explore and enhance their career opportunities.
            </Typography>
            <br aria-hidden="true" />
            <Typography variant="h3" tabIndex="0">
              Key Stages
            </Typography>
            <Typography variant="body1" tabIndex="0">
              These stages are for general guidance only and can be adjusted to
              specific student and placement circumstances.
            </Typography>
            <br aria-hidden="true" />
            <Typography variant="h4" tabIndex="0">
              Pre-placement – Months 25-30 of the cohort
            </Typography>
            <Typography tabIndex="0">
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>
                  Month 1: Placement training and information session
                </ListItem>
                <ListItem>Month 2-3: Placement organisation</ListItem>
                <ListItem>Month 4-5: Placement selection</ListItem>
              </List>
            </Typography>
            <br aria-hidden="true" />
            <Typography variant="h4" tabIndex="0">
              During Placement – Months 30-36 of the cohort (from 3 to 6 months
              in duration)
            </Typography>
            <Typography tabIndex="0">
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>Tri-partite agreement</ListItem>
                <ListItem>Partner induction</ListItem>
                <ListItem>Placement project</ListItem>
                <ListItem>Workplace Learning Journal</ListItem>
                <ListItem>
                  Communication schedule with supervisory team
                </ListItem>
              </List>
            </Typography>
            <br aria-hidden="true" />
            <Typography variant="h4" tabIndex="0">
              Post Placement – 1 month after completion of placement
            </Typography>
            <Typography tabIndex="0">
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>Complete assessment forms</ListItem>
                <ListItem>Complete Workplace Learning Journal</ListItem>
                <ListItem>Submit all assessment forms</ListItem>
                <ListItem>Provide feedback on placement</ListItem>
                <ListItem>
                  Assessment completed by the HEI for award of ECTS credits or
                  ADVANCE Work Placement Badge if local HEI credits not
                  possible.
                </ListItem>
              </List>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={0} sm={0} md={1} lg={1} />
        <Grid item xs={12} sm={12} md={4} lg={4} order={{ xs: 2, sm: 2 }}>
          {dbnewsletters[0] && <SideBar props={dbnewsletters[0]} />}
        </Grid>
      </Grid>

      <BottomBanner />
    </>
  );
};

export default PlacementProgrammePage;
