import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import LinkedIn from "@mui/icons-material/LinkedIn";
import School from "@mui/icons-material/School";

import { Navigate, useNavigate } from "react-router-dom";
import { BorderStyle, BorderTop } from "@mui/icons-material";
import "./newPersonPaper.css";

export default function NewPersonPaper(props) {
  const [buttonColor, setButtonColor] = useState("#112B19");

  const styles = {
    paper: {
      backgroundColor: "white",
      display: "grid",
      maxWidth: "300px",
      height: "auto",
      margin: "10px",
      boxShadow: "1px 1px 2px black",
    },
    img: {
      width: "100%",
      height: "auto",
    },
    button: {
      height: "50px",
      width: "50px",
      border: "0",
      borderRadius: "50%",
      backgroundColor: buttonColor,
      color: "white",
      margin: "5px",
      cursor: "pointer",
      fontSize: "1rem",
    },
    buttonContainer: {
      display: "flex",
      borderTop: "1px solid #112B19",
    },
    textName: {
      marginLeft: "10px",
      fontSize: "1.5rem",
      color: "#112B19",
    },
    textDetails: {
      marginLeft: "10px",
      fontSize: "1rem",
    },
  };

  const person = props.props;
  const universities = [
    "Other",
    "University College Cork",
    "Munster Technological University",
    "Trinity College Dublin",
    "Technological University Dublin",
    "Maynooth University",
  ];
  const navigate = useNavigate();
  const [image, setImage] = useState("profile.png");

  async function handleDetailsClick(e) {
    e.preventDefault();
    navigate(`/people/${e.currentTarget.value}`);
  }

  async function handleImage() {
    if (person.profileImage) {
      setImage(person.profileImage);
    } else {
      setImage("profile.png");
    }
  }

  useEffect(() => {
    handleImage();
  }, [person.profileImage, image]);

  return (
    <div className="profile-paper">
      <img className="profile-img" src={require(`../profileImages/${image}`)} />
      <Typography class="text-name">
        {person.firstName} {person.surname}
      </Typography>
      {!!person.isStudent && (
        <Typography class="text-details">Student</Typography>
      )}
      {!!(person.isSupervisor && !person.isExec) && (
        <Typography class="text-details">Supervisor</Typography>
      )}
      {!!(person.isExec && !person.isDirector) && (
        <Typography class="text-details">Co-Lead</Typography>
      )}
      {!!person.isDirector && (
        <Typography class="text-details">Director</Typography>
      )}
      {!!(person.isAdmin && person.firstName === "James") && (
        <Typography class="text-details">Administrator</Typography>
      )}
      {!!(person.isAdmin && person.firstName === "Luis") && (
        <Typography class="text-details">Programme Manager</Typography>
      )}
      <Typography class="text-details">
        {universities[person.universityID]}
      </Typography>
      <div className="button-container">
        <button
          id={person.firstName + person.surname}
          className="profile-button"
          onClick={(e) => handleDetailsClick(e)}
          value={Object.values(person)[0]}
        >
          <PersonIcon fontSize="large" />
        </button>
        {!!person.linkedin && (
          <a href={person.linkedin}>
            <button className="profile-button">
              <LinkedIn fontSize="large" />
            </button>
          </a>
        )}
        {!!person.researchLink && (
          <a href={person.researchLink}>
            <button className="profile-button">
              <School fontSize="large" />
            </button>
          </a>
        )}
      </div>
    </div>
  );
}
